* {box-sizing: border-box;}
/*
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}
*/

.contact-form {
  max-width: 510px;
  margin: auto;
}

.contact-form__container {
  border: 1px solid #f5f5f5;
  padding: 35px;
  box-shadow: 2px 2px 10px 0 #f5f5f5;
}
.contact-form__container p{
  margin: 0;
  text-align: left;
}
.contact-form__header h2{
  color: #4a4a4a;
}
.contact-form__header p{
  margin-bottom: 0;
  color: #4a4a4a;
  color: #311c56;
}
.contact-form__header{
  background: #f7f7f7;
  /*padding: 2px 35px 31px;*/
  padding: .5rem;
  margin: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
p {
  text-align: center;
  font-weight: 500;
}

input[type=text],input[type=number], input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    font-size: 16px;
}

input[type=submit] {
    background-color: #311c56;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 48px;
}
textarea{
  height: 140px;
}
input[type=submit]:hover {
    background-color: #140c23;
}

.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}
.sucsess  {
  margin: 20px 0;
  border: 1px solid green;
  padding: 7px;
  font-weight: bold;
}

.error {
  margin: 20px 0;
  border: 1px solid red;
  padding: 7px;
  font-weight: bold;
}


form>label{
    /*display: none;*/
    font-size: 12.8px;
}
.d-block{
  display: block;
  float: left;
}
.label-radio{
  display: initial;
}
.label-radio-ml{
  margin-left: 16px;
}
.submit-button{
  margin: 1rem 0;
}
.small-text{
  font-size: 12.8px;
  color: gray;
  margin-top: 8px !important;
}
.m_t_2{
  margin-top: 2rem;
}
.mini-button-g{
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  text-decoration: none;
  background-color: darkgrey;
  color: white;
  padding: 0.2rem;
  border-radius: 0.3rem;
  font-size: .9rem;

}

.item-input-form{
  text-align: left;
}
.item-input-form>h4{
  margin-top: 0;
  text-align: center;
}
.p-height-07{
  line-height: .7rem;
}

.input-detail-tag{
  margin-top: .5rem;
  font-size: .8rem;
  color: gray;
  text-align: left;
}

/*編集時の画像エリア*/
.edit-img-box{
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;
  position: relative;
}
.edit-img{
  position: relative;
  max-width: 120px;
}
.edit-img>img{
  max-width: 80px;
  max-height: 80px;
  margin: 0 8px;
}
.trash-icon{
  width: 24px;
  cursor: pointer;
}
.input-img-label{
  background-color: #4245a8;
  color: white;
  padding: 0.2rem;
  max-height: 4rem;
  border-radius: 0.3rem;
}
.input-img-label>span{
  white-space: nowrap;
}
.input-img-tag{
  display: none;  
}
.edit-img>.text-type{
  margin-top: .6rem;
}
.edit-input-img-area{
  position: absolute;
  bottom: -80px;
}
.button-m-t{
  margin-top: 112px;
}
.mt-1-style{
  margin-top: 1rem !important;
}
.input-ban{
  pointer-events : none;
  background-color: #eee;
}
.submit-on{
  pointer-events: none;
  opacity: 0.5;
}

.input-require:after {
  content: " *";
  color: red;
}


/* 管理 TOP */
.admin-top>h4>a{
  text-decoration: none;
}