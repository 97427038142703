/*footer*/
.transition{
    opacity: 1;
    transition:1s;
}
.not-transition{
    opacity: 0.1;
    transition:1s;
}
.call-bar{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: none;
    border-top: 3px solid green;
}
.banner-grid{
    display: grid;
    grid-template-columns: 9fr 8fr 6fr;
    align-items: center;
    background-color: #e5eee6;
    color: black;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
}
.footer-grid-child{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.icon-big{
    font-size: 2.1rem !important;
}

.call-text{
    color: #000;
    text-decoration: none;
}
.call-text-area{
    font-size: .8rem;
    line-height: 1rem;
    font-weight: bold;
    white-space: nowrap;
}
.call-text-area-img{
    width: 40px;
    position: absolute ;
    left: 150px;
    top: 1px;
}
.call-text-area>nav>ul>li:first-child>a{
    font-size: 2.5rem;
}
.banner-text1{
}
.banner-text1>p{
    white-space: nowrap;
    margin: 0px;
}
.banner-text1>p:first-child{
    font-size: .8rem;
    color: #d73a49;
}
.banner-text1>p:nth-child(2){
    /*font-size: 1.4rem;*/
}
.go-top{
    text-align: center;
}
.go-top>img{
    width: 40px !important;
}
.bannar-label{
    position: absolute;
    top: -15px;
    left: 12px;
    color: green !important;
    padding: 0.05rem 0.5rem;
    font-size: .7rem;
    background-color: white;
    border-radius: .3rem;
}



/*ハンバーガーメニュ*/
.control-arrow{
    display: none;
}
.call-text-area>nav>ul{
    padding-left: 0px;
}

/*モーダル*/
.modal-reserve>div>.ReactModal__Content--after-open{
    border: 3px solid green !important;
    inset:1rem !important;
    height: 600px;
}
.close-button>button{
    position: absolute;
    border: 0px;
    background-color: white;
    right: -0.3rem;
    top: 0rem;
}
.close-button>button>img{
    width: 2rem;
}
.form-input-row{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.7rem;
}
.form-input-row>div{
    margin:.3rem 0;
}

.form-input-row>div:first-child{
    width: 60px;
}
.form-input-row>div:nth-child(2)>input,
.date-picker-button{
    border-color: rgba(0,0,255,.4196078431372549);
    border-radius: .3rem;
    width: 220px;
    height: 1.5rem;
    text-align: center;
    font-size: 1rem;
}
.form-input-row>div>label{
    font-size:0.85rem
}
.form-input-row>div>input{
    margin-bottom: initial;
    font-size: 16px;
}
.date-picker-button-hidden{
    display: none;
}
.reserve-submit-button{
    margin: 1rem;
    display: flex;
    justify-content: center;
}
.reserve-submit-button>input{
    border: 0px;
    font-size: 1.2rem;
    padding: .5rem 2rem;
    background-color: #007bff;
    color: white;
    border-radius: .5rem;
}
.display-none{
    display: none;
}
.notification-text-box{
    font-size: .8rem;
    color: gray;
}

/* カレンダー */
.react-datepicker-wrapper{
    position: absolute;
    right: 0;
}
.calender-select{
    margin-bottom: 0;
}
.react-datepicker__header:first-child{
    padding-top: 0;
}
.react-datepicker-time__header{
    padding-top: .5rem;
}
.close-on-calender{
    left: -18px;
    top: -12px;
    position: absolute;
    z-index: 3;
    display: none;
    background-color: gray;
    color: white;
    padding: 0.1rem 0.8rem;
    border-radius: 0.3rem;
}
.on-calender>button{
    background-color: transparent;
}
.on-calender>button>img{
    width: 1.6rem;
}
.calsender-label{
    position: relative;
}

body > div.modal-reserve > div > div > div.Reserve > form > div.form-input-row.m-b-15 > div:nth-child(2) > div.react-datepicker__tab-loop > div.react-datepicker-popper > div > div > div.react-datepicker__time-container > div.react-datepicker__header.react-datepicker__header--time > div

/*予約フォーム*/
.Reserve>h2{
    letter-spacing: .2rem;
    text-align: center;
}
.Reserve>p{
    text-align: center;
}
.calender-select{
    font-size: 1rem;
}

.height-19{
    height: 1.8rem !important;
    margin-top: .3rem;
}
.input-border-initial{
    border-color: initial !important;
}
.input-radio-box{
    display: inherit;
}

/*仏具*/
.title_underline{
    background: linear-gradient(transparent 70%, #a6d3c8 70%);
    display: inline-block;
    width: 50%;
    letter-spacing: .3rem;
}
.ItemOption{
    margin-bottom: 1.5rem;
    border-bottom: 1px solid lightgray ;
}
.ItemOption:nth-of-type(5){/*last-of...効かない*/
    border-bottom: 0px ;
}


/*商品ページのオプション*/
.option-area{
    margin: 0 1rem;
}
.option-grid-box{
    display: grid;
    grid-template-columns: 90px auto 50px;
    width: 100%;
}
.option-grid-box>div:nth-child(1),
.option-grid-box>div:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: center;
}
.option-grid-box>div:nth-child(2){/*商品ページに表示されるオプションタイトルと価格*/
    display: grid;
    grid-template-columns: 1fr;
}
.option-grid-box>div:nth-child(2)>div:first-child{
    display: flex;
    align-items: end;
    justify-content: center;
    font-weight: bold;
    margin-bottom: .1rem;
}
.option-grid-box>div:nth-child(2)>div:last-child{
    display: flex;
    align-items: start;
    justify-content: center;
    margin-top: .1rem;
}

.option-grid-box>div>button{
    border: 0px;
    padding: .2rem .4rem;
    background-color: gray;
    color: white;
    border-radius: .3rem;
}
.modal-item-option>div{
    display: block;
    
}
.modal-item-option>div>.ReactModal__Content--after-open{
    border: 3px solid #6bcab2 !important;
    inset:1rem !important;
}
.modal-item-option>div>div>div>p{
    margin-bottom: 0.2rem;
    text-align: center;
    background-color: #b5f0e2;
}
.modal-item-option>div>div>p:not(:first-child){
    margin-top: 2.5rem;
}

/*商品ページ　オプション　モーダル*/
.option-modal{
    display: grid;
    grid-template-columns: 30px auto;
    grid-gap: .5rem;
}
.option-modal>div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
}
.option-modal>div:nth-child(2){
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 1rem;
}
.option-modal>div:nth-child(2)>div:first-child{/*画像*/
    display: flex;
    align-items: center;
    justify-content: center;
}
.option-modal>div:nth-child(2)>div>label>img,
.modal-item-option-second>div>div>div>div:first-child>img{
    width: 100%;
}
.option-modal>div:nth-child(2)>div:nth-child(2){/*オプション紹介文章欄*/
    margin-right: .5rem;
}
.option-modal>div:nth-child(2)>div:nth-child(2)>label>p{
    margin: 0px;
}
.option-modal>div:nth-child(2)>div:nth-child(2)>label>p:first-child{
    font-weight: bold;
    border-bottom: 1px solid lightslategray;
    text-align: center;
    margin-top: .2rem;
}
.option-modal>div:nth-child(2)>div:nth-child(2)>label>p:nth-child(2){
    text-align: center;
    margin: .5rem 0;
}
.option-modal>div:nth-child(2)>div:nth-child(2)>label>p:last-child{
    font-size: .9rem;
}
.modal-item-option>div>div>h2{
    text-align: center;
}
.modal-close{
    text-align: center;
    margin-top: 2rem;
}
.modal-close>button{
    border: 0px;
    padding: .5rem 1rem;
    background-color: gray;
    color: white;
    border-radius: .5rem;
}
.go-option-item-link-button{
    font-size: .9rem;
    text-decoration: none;
    white-space: nowrap;
    border: 0px;
    padding: .2rem .4rem;
    background-color: #6bcab2;
    color: white;
    border-radius: .3rem;
}
.zeikomi{
    font-size: .8rem;
}


/*モーダルチルド*/
.modal-item-option-second>div>.ReactModal__Content--after-open{
    border: 1px solid #a5cade !important;
    inset:.8rem !important;
}
.modal-item-option-second>div>div>h2{
    text-align: center;
}
.modal-item-option-second>div>div>div>div:nth-child(2){/*オプション　モーダルチルド　価格*/
    margin: 1rem 0;
    font-size: 1.1rem;
    text-align: center;
}



/*mobile*/
@media(max-width:480px){
    .option-grid-box>div>img{
        width: 80px;
    }
}
