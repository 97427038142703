.hidden-area{
    overflow: hidden;
    height: .001px;
}

#pdf{
    width: 210mm;
    min-height: 297mm;
    padding: 2rem 4rem;
    padding-top: 5rem;
}
#pdf>p{
    text-align: right;
    margin-right: 3rem;
}
#pdf>div:nth-child(3){/*なぜか３*/
    display: flex;
    align-items: center;
    justify-content: center;
}
#pdf>div>table {
    width: 90%;
    border: 1px solid;
    border-collapse: collapse;
    border-spacing: 0;
}
#pdf>div>table>thead{
    background-color: black;
    color: white;
}
#pdf>div>table td{
    border-color:black;
    border-style:solid;
    border-width:1px;
    overflow:hidden;
    padding:.8rem .5rem;
    word-break:normal;
}
#pdf>div>table>tbody>tr>td:first-child{
    text-align: center;
}
#pdf>div>table>tbody>tr>td:nth-child(2),
#pdf > div > table > tbody > tr:nth-child(7) > td:nth-child(1),
#pdf > div > table > tbody > tr:nth-child(8) > td:nth-child(1),
#pdf > div > table > tbody > tr:nth-child(9) > td:nth-child(1){
    text-align: left;
    padding-left: 2rem;
}
#pdf>div>table>tbody>tr>td:nth-child(3),
#pdf > div> table > tbody > tr:nth-child(7) > td:nth-child(2),
#pdf > div> table > tbody > tr:nth-child(8) > td:nth-child(2),
#pdf > div> table > tbody > tr:nth-child(9) > td:nth-child(2){
    text-align: right;
    padding-right: 1rem;
}

.no{
    font-size: .9em;
}

.pdf-footer{
    margin-top: 3rem;
}
.pdf-footer > div:first-child{
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

}
.pdf-footer > div{
    margin-bottom: .5rem;
}
.pdf-footer>div>img{
    height: 3rem;
    margin-right: 1rem;
}



#exportPdf{
    border: 0px;
    background-color: #fa0f00b8;
    color: white;
    padding: .5rem 2rem;
    border-radius: .3rem; 
}
