
.admin-header{
    display: grid;
    grid-template-columns: 20% 50% 30%;
    align-items: center;
    justify-content: center;
    padding: .5rem 1rem;
    font-size: .9rem;
    background-color: darkgreen;
}
.admin-header>div>a{
    text-decoration: none;
    color: white;
}

.admin-top>ul{
    list-style: none;
    text-align: left;
}
.admin-top>ul>li{
    margin: .3rem 0;;
}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(248, 248, 248, 0.9);
    z-index: 2;
    cursor: pointer;
  }

.decoration-none{
    text-decoration: none;
}
.button-css-none{
    background-color: white;
    border: 0px;
}

/*モーダル　管理画面　顧客情報*/
.modal-customer-view>div>.ReactModal__Content--after-open{
    border: 3px solid gray !important;
    inset:1rem !important;
    max-height: 400px;
    top: 15% !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.modal-customer-view-long>div>.ReactModal__Content--after-open{
    max-height: 550px;
    top: 5% !important;
}
.admin-view-customer-data{
    /*margin-top: 2.5rem;*/
}
.admin-view-customer-data>ul>li{
    margin-bottom: 1rem;
}
.admin-view-customer-data>ul>li>span{
    font-weight: bold;
}

/* 商品一覧 */
.item-list-admin{
    padding-left: .5rem;
}
.list{
    list-style: none;
    padding-left: 0.5rem;
    overflow-x: scroll;
    margin-bottom: 5rem;
}
.list>li>div{
    display: grid;
    grid-template-columns: 30px 220px 60px 50px 50px 50px;
    align-items: center;
    min-height: 50px;
}
.list>li>div>div:nth-child(2){
    white-space: nowrap;
    text-align: left;
    margin-left: .6rem;
}
.list>li>div>div>img,
.admin-list-img{
    max-width: 30px;
}
.dragged>div{
    opacity: .5;
    display: flex;
}
.dragged{
    list-style-type: none;
}
span.fontsize-s{
    font-size: .8rem;
}
p.fontsize-s{
    font-size: .8rem;
    padding-bottom: .2rem;
}
.no-link{
    pointer-events: none;
}

/*カレンダー*/
.css-4plepo .MainLayout-header,
.css-99cbwm .MainLayout-header{
    z-index: 1 !important;
}
.ReactModal__Overlay--after-open{
    z-index: 3;
}


/* チャート */
.viewMenu{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.viewMenu>div{
    margin: 1rem .8rem;
    font-size: .9rem;
    color: blue;
}
.display-none{
    display: none;
}
.chartArea{
    margin-left: auto;
    margin-right: auto;
    margin: 10px;
    width: 95%;
    margin-bottom: 5rem;
    margin-top: 0px;
}
.black{
    color:black !important;
    font-weight: bold;
}