.feature-h2{
    position: relative;
    /*padding-bottom: 1.1rem;*/
    font-size: 1.25rem;
}
.feature-img{
    width: 100%;
}
.feature-mini-title{
    position: absolute;
    font-size: .9rem;
    bottom: .2rem;
    color: white;
    right: 1rem;
}
.feature-mini-text{
    font-size: .76rem;
    color: grey;
}
.color-bk{
    color: darkgrey;
}
.feature-text{
    text-align: left;
    margin: 1rem;
    line-height: 1.8rem;
    font-size: .95rem;
}
.feature-text>ul{
    padding-left: 1rem;
}
.feature-text>div>p{
    text-align: left;
}
.feature-image-label{
    margin-top:.35rem !important;/* 1rem */
    margin-left: -1rem;
    margin-right: -1rem;
}
.feature-text>div>img,
.feature-image-label>img{
    width: 100%;
    margin: 1rem 0;
}
.feature-text>div>img{
    margin-left: -1rem;
    width: 100vw;
}
.feature-text>div>h4{
    text-align: center;
    margin-bottom: 0rem;
}
.feature-text-x2{
    margin-left: 2rem;
    margin-right: 2rem;
}
.feature-image-label>p{
    font-size: .9rem;
    margin-bottom: -1rem;
}
.access-ul>li{
    margin-bottom: .5rem;
}
.access-li{
    white-space: nowrap;
}
#feature-img-3-1-bg{
    background-image: url(https://kyoto-butsudan.com/images/features/bg-3-1-1.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    min-height: 220px;
}
#feature-img-3-2-bg{
    background-image: url(https://kyoto-butsudan.com/images/features/bg-3-1-2.jpg);
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    min-height: 220px;
}
#feature-img-3-3-bg{
    background-image: url(https://kyoto-butsudan.com/images/features/bg-3-2-1.jpg);
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    min-height: 220px;
}
#feature-img-3-4-bg{
    background-image: url(https://kyoto-butsudan.com/images/features/bg-3-2-2.jpg);
    background-position: 39% 66%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    min-height: 220px;
}
#feature-img-3-5-bg{
    background-image: url(https://maruichi-kyoto.com/img/ihori-hole20210901.jpg);
    background-position: 39% 66%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    min-height: 220px;
}
#feature-img-3-6-bg{
    background-image: url(https://maruichi-kyoto.com/img/hall-top.jpg);
    background-position: 39% 66%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    min-height: 220px;
}
#feature-img-4-1-bg{
    background-image: url(https://kyoto-butsudan.com/images/features/bg-4-1-1.jpg);
    background-position: 23% 66%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    min-height: 220px;
}
#feature-img-4-2-bg{
    background-image: url(https://kyoto-butsudan.com/images/features/bg-4-1-2.jpg);
    background-position: 48% 66%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    min-height: 220px;
}
.feature-img-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    margin-bottom: .5rem;
}
.link-button{
    margin-top: 2rem;
    text-align: center;
}
.p-center{
    text-align: center !important;
}
.p-margin-zero{
    margin-bottom: 0px !important;
}
.text-bold{
    font-weight: bold;
}
.text-margin-0{
    margin: 0;
}
.img-size-label{
    position: absolute;
    white-space: nowrap;
    left: 18px;
    top: 195px;
    font-size: .8rem;
}


/*シングルページ*/
.price-box-3{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: .9rem;
}
.price-box-3>div:not(:last-child){
    border-right: 1px solid #d7d7d7;
    padding-bottom: 0.5rem;
}
.price-box-3>div>img{
    width: 100%;
    min-height: 145px;
}


.mokuji{
    padding: 0.2rem 1rem;
    border: 1px solid lightblue;
    border-radius: 0.5rem;
}
.mokuji>ol{
    padding-left: 1rem;
    font-size: .85rem;
}
.mokuji>ol>li>a{
    text-decoration: none;
}
/*会社概要用*/
.table-grid{
    display: grid;
    grid-template-columns: 22% 78%;
    background-color: #fafad9;
    align-items: center;
    border: 0.2px solid #eee;
    font-size: .7rem;
}
.table-grid>div:first-child{
    text-align: center;
    
}
.table-grid>div{
    padding: .25rem 0;
}
.table-grid>div>ul{
    padding-left: 0.5rem;
}
.table-grid>div:nth-child(2){
    padding-left: 1rem;
    background-color: white;
}
.table-grid>div>p{
    text-align: left;
    margin: 0;
}
.p-mb-minus-03{
    margin-bottom: -0.3rem !important;
}
.line-heoght-15{
    line-height: 1.5rem;
}
.line-height-12{
    line-height: 1.2rem;
}
.line-height-1{
    line-height: 1rem;
}

/* 地図のレビュー */
.map-box{
    margin: 2rem 0;
    /*padding: 0 1rem;*/
    margin-top:.75rem !important;/* 1rem */
    position: relative;
}
.checked {
    color: orange;
}
.map-text-area{
    font-size: .8rem;
    padding: .5rem;
    position: absolute;
    top: 3px;
    left: 4px;
    background-color: white;
    border-radius: .3rem;
}
.map-text-area>div{
    line-height: 1.3rem;
}
.map-text-area>div>span>a,
.map-text-area>div>a{
    text-decoration: none;
    color: #1a73e8;
}
.map-rating-number{
    margin-right: .5rem;
}
.map-review-link{
    margin-left: .5rem;
}
