/* 全体 */
html{scroll-behavior:smooth}
/*
#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}
*/
section{
    margin-top: 5em;
}
section:first-child{
    margin-top: 3rem;
}
section>h2{
    margin-bottom: 0rem;
    /*letter-spacing: .4rem;*/
}
.show-detail-button{
    margin-top: 1.2rem;
}
.show-detail-button>a{
    font-size: 2rem;
    margin: 0;
    padding: 1rem 5rem;
    color: #019772;
    font-weight: initial;
    border: 2px solid #019772;
    text-decoration: none;
}
.Home>section>p{
    font-size: .95rem;
}

/*タイトル*/
.pankuzu{
    text-align: left;
    padding-left: 1rem;
    color: lightslategray;
    margin: .5rem 0;
    display: none;
}
.pankuzu>a{
    text-decoration: none;
    color: lightslategray;
    font-size: 1rem;
}

/*スライダー*/
.legend{
    font-size: 1.1rem !important;
}
.carousel-status{
    font-size: 1.1rem !important;
}
.thumbs{
    margin-top: 0px;
}
.top-img{
    width: 900px;
}
.carousel-status,
.display-none{
    display: none;
}
.thumbs.animated {
    margin-bottom: 0px;
}
.thumbs.animated::-webkit-scrollbar {
    display: none;
}
.carousel .thumbs-wrapper{
    margin-bottom: -2px;
    margin-top: .5rem;
}
.top-hero{
    width: 100%;
    margin-top: .1rem;
}
.top-carousel{
    /*margin-top: 1.5rem;*/
    margin-top: 0.4rem;
    margin-bottom: 0.2rem;;
}
.thumbs-wrapper>ul>li:last-child{
    display: none;
}
.thumbs-wrapper>ul>li:nth-child(1){
    margin-right: .9rem;
}
.thumbs-wrapper>ul>li:nth-child(2){
    margin-right: .9rem;
}
.thumbs-wrapper>ul>li:nth-child(3){
    margin-right: 0;
}

/* 20230305追加 */
.carousel-parent{
    position: relative;
}
/* HERO1 */
.carousel-child{
    position: absolute;
    bottom: var(--carousel-text-bottom);/* 2rem */
    right: var(--carousel-text-right); /* 3rem */
    font-size: var(--carousel-text-size); /* 3.2rem */
    letter-spacing: var(--carousel-text-space); /* .35rem */
    color: #000;
    /*
    -webkit-text-stroke: 1px #000;
    text-stroke: 1px #000; 
    */
    font-weight: bold;
    text-shadow: 0 0 5px #fff,0 0 5px #fff,0 0 5px #fff,0 0 5px #fff; 
}

/* HERO2 */
.carousel-child-2-0{
    position: absolute;
    left: 0;
    top: var(--carousel-2-img-top);
    box-shadow: 0px 0 15px rgb(0 0 0 / 52%);
    /*box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 下部分シャドウ*/
}
.carousel-child-2-1{ /* あかね */
    position: absolute;
    left: var(--carousel-2-font-1-left);
    top: var(--carousel-2-font-1-top);
    font-size: var(--carousel-2-font-size);
    font-weight: bold;
    letter-spacing: 3px;
}
.carousel-child-2-1 span, .carousel-child-2-2 span, .carousel-child-2-3{
    font-size: var(--carousel-2-span-font-size);
    letter-spacing: 0px;
}
.carousel-child-2-2{
    position: absolute;
    bottom: var(--carousel-2-2-bottom);
    right: var(--carousel-2-2-right);
    font-size: var(--carousel-2-2-font-size);
    font-weight: bold;
}
.carousel-child-2-3{
    position: absolute;
    bottom: var(--carousel-2-3-bottom);
    right: var(--carousel-2-3-right);
}

/* HERO3 */
.carousel-child-3-1{
    position: absolute;
    left: var(--carousel-3-1-left);
    top: var(--carousel-3-1-top);
    font-weight: bold;
    font-size: var(--carousel-3-1-text-size);
    letter-spacing: var(--carousel-3-1-text-space);
    text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff;
}
.carousel-child-3-1 span, .carousel-child-3-3 span{
    font-size: var(--carousel-3-1-span-text-size);
}
.carousel-child-3-3{
    position: absolute;
    left: var(--carousel-3-2-left);
    top: var(--carousel-3-2-top);
    font-size: var(--carousel-3-2-text-size);
    text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff;
}





/*商品一覧*/
.item-sort{
    width: 100%;
    text-align: center;
    padding: .5rem 0;
    border: 2px solid rgb(0 0 255 / 28%);
    font-size: 1rem;
}
.pb-1{
    padding-bottom: 1rem !important;
}
.view-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-icon>button{
    margin: 0.5rem 1rem;
    border: 0px;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    font-size: 1rem;
}
.view-icon>button:nth-child(2){
    margin-left: 0;
}
.notification-text{
    font-size: .8rem !important;
    color:gray;
}
/*
.grid-gap-butsudan{
    grid-gap: 1rem 0;
}
*/
.grid-gap-butsudan,
.grid-gap-butsugu{
    grid-gap: 2rem 0.5rem;
    font-size: .9rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.grid-gap-butsudan>div>a,
.grid-gap-butsugu>div>a{
    text-decoration: none;
}
.butsugu-list-text{
    text-shadow: 0px 0px 0 #000 !important;
    color: #000 !important;
    top: 100% !important;
}
.butsugu-list-view-grid{
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
}
.butsugu-list-view-grid>div>img{
    width: 100%;
}
._998_label{
    position: absolute;
    bottom: 4vh;
    font-size: 1rem;
    right: 2vw;
    color: white;
    background-color: #0080009e;
    padding: 0.1rem 0.8rem;
    border-radius: 0.3rem;
}




/* All View*/
.item-content-total{
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    margin-top: 1.5rem;
}
.item-content-butsudan-2{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    margin-top: 1.5rem;
}

.item-box-relative{
    position: relative;
}
.item-box-relative>p{
    position: absolute;
    width: 100%;
    margin: 0;
    color: white;
    top: 60%;
    text-shadow:1px 1px 0 #000,-1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000;
}
.item-box-relative>img{
    max-width: 90%;
}


/*特徴*/
section.feature>h2{
    margin-top: 1rem;
    margin-bottom: .5rem;
}
section.feature>h3,
.feature-h3{
    font-size: 1rem;
}
.feature-content{
    padding: 1rem 0;
    background-image: url(https://kyoto-butsudan.com/img/11.jpg);
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: cover;
}
.feature-content>div>ul{
    list-style: none;
    text-align: left;
}
.feature-content>div>ul>li{
    margin:1.5rem 0px;
}
.feature-content>div{
    width: 640px;
    display: flex;
    margin: 0 auto;
}
.feature-list-content{
    display: grid;
    grid-template-columns: 40px auto;
}
section>p>span,
.footer-sitemap>div>a>span,
.feature-text>div>span,
.feature-text>div>p>span,
.feature-text>ul>li>span,
.kaigyou-no,
.table-grid>div>span,
.table-grid>div>p>span,
.table-grid>div>ul>li>span,
.pl-1>li>span,
.nowrap-text{
    display: inline-block;
    white-space: nowrap;
}
.fontsize-light{
    font-size: 1.5rem !important;
}
.mb-0{
    margin-bottom: 0px;
}
.mt-0{
    margin-top: 0px !important;
}
.mt--05{
    margin-top: -0.5rem !important;
}
.mb-45{
    margin-bottom: 4.5rem;
}
.mb-05{
    margin-bottom: .5rem;
}
.mb-07{
    margin-bottom: .7rem;
}
.mb-02{
    margin-bottom: .2rem;
}
.m-b-15{
    margin-bottom: 1.5rem;
}
.mt-08{
    margin-top: 0.77rem !important;
}
.mt-03{
    margin-top: 0.3rem !important;
}
.mt-13{
    margin-top: 1.3rem !important;
}
.mt-1{
    margin-top: 1rem;
}
.mt-2{
    margin-top: 2rem;
}
.mt-3{
    margin-top: 3rem;
}
.mt-4{
    margin-top: 4rem;
}
.mt-06{
    margin-top: 0.6rem !important;
}
.mt-025{
    margin-top: -0.25rem;
}
.mr-02{
    margin-right: .2rem;
}
.mr-05{
    margin-right: .5rem;
}
.mr-08{
    margin-right: .8rem;
}
.pb-05{
    padding-bottom: .5rem !important;
}
.pb-08{
    padding-bottom: .8rem !important;
}
.pb-0{
    padding-bottom: 0rem !important;
}
.pl-1{
    padding-left: 1rem;
}
.pr-01{
    padding-right: .1rem;
}
.pr-05{
    padding-right: .5rem;
}
.pt-03{
    padding-top: .4rem;
}
.pt-06{
    padding-top: .75rem;
}
.pt-1{
    padding-top: 1rem;
}
.w-105{
    width: 85px !important;
}
.ul-mark-kome{
    list-style:none;
}
.ul-mark-kome>li{
    text-indent:-1.1rem;
}
.ul-mark-kome>li:first-child::before{
    content:"＊"; 
    margin-right:0.1em;
}
.ul-mark-kome>li:nth-child(2)::before{
    content:"＊"; 
    margin-right:0.1em;
    color: white;
}
.mx-minus-02{
    margin: 0 -0.2rem;
}
.mx-minus-01{
    margin: 0 -0.1rem;
}
.t-center{
    text-align: center;
}
.t-center-important{
    text-align: center !important;
}
.t-left{
    text-align: left !important;
}
.t-right{
    text-align: right !important;
}
.t-red{
    color: red;
}
.t-small{
    font-size: .8rem;
    margin: 0;
}
.font-size-85{
    font-size: .85rem;
}
.op-1{
    opacity: 1;
    transition: 1.5s ;
}
.op-02{
    opacity: 0.05;
}
.p-relative{
    position: relative;
}
.font-weight-initial{
    font-weight: initial;
}
.font-size-inital{
    font-size: initial !important;
}
.pointer-events-no{
    pointer-events: none;
}
.letter-sp-2{
    letter-spacing: .2rem;
}
.letter-sp-1{
    letter-spacing: .3rem;
}
.letter-sp-7{
    letter-spacing: .72rem;
}
/*商品*/
.item-content{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}
.item-content>div{
    display: grid;
    grid-template-columns: 400px 400px;
    grid-gap: 3rem;
}
.item-box>h3{
    margin: .5rem 0;
}
.item-box>img{
    /*height: 350px;*/
    max-width: 90%;
}
.item-content>div>div>a{
    color: black;
    text-decoration: none;
}
.page-item-margin{
    margin-top: 2rem;
}
.item-detail>ul{
    list-style: none;
    font-size: 1rem;
    padding: 0 1rem;
}
.item-detail>ul>li{
    margin: 1rem 0;
}
.item-detail-grid{
    display: grid;
    grid-template-columns: 1fr 3fr;
    border-bottom: 1px solid lightgray;
}
.item-detail-title{
    margin-top: 5rem;
    background-color: #878787;
    color: white;
    padding: .5rem 0;
}
.item-status-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 3rem;
    margin-left: .5rem;
    margin-right: .5rem;
    justify-items: center;
}
.item-status-box>div{
    width: 100px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #6dc6b0;
    border-radius: .5rem;
}
.item-closing{
    margin-top: 3rem;
}
/*
.item-closing>button{
    background-color: #007bff;
    color: white;
    padding: 1rem;
    font-size: 1.4rem;
    border:0px;
    border-radius: .5rem;
}
*/


.single-page-ul{
    padding-left: .5rem;
}


/*footer*/
footer{
    margin-top: 5rem;
    margin-bottom: 3rem;
    background-color: #F8F5FF;
    padding: 0 1.25rem;
}
.footer{
    /*display: flex;*/
    /*justify-content: center;*/
    padding: 2rem 1.25rem;
    max-height: 56px;
    display: contents;
}
.footer-info{
    display: grid;
    /*grid-template-columns: 600px 300px;*/
    grid-template-columns: 600px;
    justify-items: center;
    align-items: center;
}
.footer-info>.text-box{
    margin-left: -1rem;
    margin-top: 2rem;
}
.footer-info>.text-box>.footer-title{
    white-space: nowrap;
    margin-bottom: 0;
}
.footer-info>.text-box>p{
    font-size: .68rem;
    font-weight: bold;
}
.footer-info>.img-box>img{
    width: 100%;
}
.footer-info>.img-box{
    background-color: #eee;
}
.footer-info-ul{
    padding-left: 1rem;
    margin-top: .5rem;
    list-style: none;
}
.footer-info-ul>li{
    margin: .5rem 0rem;
    /*font-size: .68rem;*/
    font-size: 12.5px;
    white-space: nowrap;
}
.footer-info-ul>li:first-child,
.footer-info-ul>li:last-child{
    list-style: none;
    /*text-align: center;*/
    font-weight: bold;
}
.footer-info-ul>li:first-child{
    text-align: left;
}
.footer-info-ul>li>a{
    text-decoration: none;
    color: black;
}
.copyright{
    text-align: center;
    font-size: 1rem;
    margin: 1rem 0;
    padding-bottom: 2rem;
    margin-top: 2rem;
}
.hr-title{
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: .9rem;
    margin-bottom: .2rem;
    margin-top: 1rem;
}
.hr-title::before{
    content: '';
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 30%;
    height: 2px;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    left: 0;
}
.hr-title::after{
    content: '';
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 30%;
    height: 2px;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    right: 0;
}
.text-hr {
    display: flex;
    align-items: center;
    font-family: sans-serif;
    width: 100%;
    font-size: .7rem;
}
.text-hr__text {
    padding-right: 15px;
}
.text-hr__text:not(:first-child) {
    display: none;
}
.text-hr::after {
    flex: 1;
    background: #c7c7c7;
    content: "";
    height: 1px;
}
.footer-li-grid{
    display: grid;
    grid-template-columns: 14% 86%;
    align-items: center;
    /*margin-left: -1rem;*/
}
.footer-li-grid>div>img{
    width: 2rem;
}
.footer-li-grid>div:nth-child(2){
    font-size: .9rem;
}



/*子ページ*/
.page-h2{
    /*background-color: #6dc6b0;*/
    /*color: white;*/
    /*padding: 1rem 0;*/
    letter-spacing: .15rem;
    margin: 0px;
    
    padding-top: .9rem;
    padding-bottom: 1.77rem;

    margin-top: 1.5rem;
}
.item-closing>button,
.link-button>a{
    font-size: 1.1rem !important;
    padding: 0.43rem 2.84rem !important;
    border: 2px solid #019772;
    color: #019772;
    background-color: white;
    text-decoration: none;
}
.link-button>a.add-padding-x{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
}


@media(max-width:480px){
    section:first-child{
        margin-top: 0rem;
    }
    section:nth-child(2){
        margin-top: 3em;
    }

    ._998_label{
        position: absolute;
        bottom: 3.3vh;
        font-size: .5rem;
        right: 7vw;
        padding: 0.1vw 2vw;
        border-radius: 0.3rem;
    }

}