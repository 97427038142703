/*
.MuiGrid-container:nth-child(4){
    display: none;
}
*/

/*カレンダー編集時の終了時間*/
/*
.MuiGrid-root.MuiGrid-container.Layout-dateEditors.css-1vam7s3-MuiGrid-root > div:nth-child(3) > div{
    display: none;
}
*/
.Layout-dateEditors>div:nth-child(3),
.Layout-booleanEditors,
.ResourceEditor-selectBox,
.MuiTypography-overline{
    display: none !important;
}
.MuiOutlinedInput-input{
    padding: 0 .5rem !important;
}

.MuiInputBase-multiline>textarea::-webkit-input-placeholder::before{
    color:#666;
    content:"Line 1\A Line 2\A Line 3\A";
}

.TextEditor-title>input{
    font: initial;
}

.Layout-dateEditors>div>div>input{
    min-width: 230px;
}

#mui-7::-webkit-input-placeholder {
    color:#666;
    content:"HELLO";
}


::-webkit-scrollbar {
    width: 12px;
}

.css-sizvqo-MuiTableCell-root.Cell-cell{
    height:90px !important;
}
/*カレンダー編集時のメモ*/


/*カレンダー編集時の種類
#root > div > div.store-schedule > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1ps6pg7-MuiPaper-root > div > div:nth-child(1) > div > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.Overlay-absolutePosition.Overlay-paper.Overlay-halfSize.Overlay-transition.css-4t3x6l-MuiPaper-root-MuiDrawer-paper > div > div.Layout-container > div > div.MuiFormControl-root.MuiFormControl-marginNormal.MuiTextField-root.ResourceEditor-selectBox.css-xbw3zd-MuiFormControl-root-MuiTextField-root{
    display: none;
}
*/

@media(max-width:480px){
    /*カレンダー枠*/
    .store-schedule{
        /*margin-left: -1rem;*/
        margin-bottom: 3rem;
    }
    .Cell-dayOfMonth{
        font-size: .9rem !important;
    }
    .css-dmmspl-MuiFormGroup-root,
    .MuiFormGroup-root{
        position: absolute;
        right: 0px;
        padding-top: 0.45rem;
    }
    .css-dmmspl-MuiFormGroup-root>label>span:nth-child(2){
        font-size: .8rem;
    }
    .Root-root{
        margin-left: .5rem !important;
    }
    .Root-root>button:nth-child(4){
        margin-left: 0.5rem;
    }
}