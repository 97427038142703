
  .round {
    border-radius: 50%;
  }
  
  .fab {
    transition: all 300ms ease-in-out;
    width: 56px;
    height: 56px;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 30px;
    bottom: 15px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    color: white;
    font-size: 2em;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16), 0px 3px 10px rgba(0, 0, 0, 0.16);
    z-index: 99;
  }
  
  .fab i {
    transition: all 300ms ease-in-out;
    will-change: transform;
  }
  
  .inner-fabs .fab {
    width: 40px;
    height: 40px;
    right: 38px;
    bottom: 23px;
    font-size: 1.5em;
    will-change: bottom;
  }
  
  .inner-fabs.show .fab:nth-child(1) {
    bottom: 80px;
  }
  
  .inner-fabs.show .fab:nth-child(2) {
    bottom: 130px;
  }
  
  .inner-fabs.show .fab:nth-child(3) {
    bottom: 180px;
  }
  
  .inner-fabs.show .fab:nth-child(4) {
    bottom: 230px;
  }
  
  .inner-fabs.show .fab:nth-child(5) {
    bottom: 280px;
  }
  
  .inner-fabs.show .fab:nth-child(6) {
    bottom: 330px;
  }
  
  .inner-fabs.show + .fab i {
    transform: rotate(135deg);
  }
  
  .fab:before {
    transition: opacity 150ms cubic-bezier(0.4, 0, 1, 1);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    color: #ececec;
    right: 50px;
    top: 25%;
    background-color: rgba(70, 70, 70, 0.9);
    font-size: 0.5em;
    line-height: 1em;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    border-radius: 2px;
    padding: 6px 8px;
    max-width: 200px;
    font-weight: bold;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  
  .inner-fabs.show .fab:hover:before {
    visibility: visible;
    opacity: 1;
  }

  .material-icons{
    position: relative;
  }
  .icon-label{
    position: absolute;
    left: -120px;
    bottom: 5px;
    display: none;
  }
  .fab>a{
      color: white;
  }
  .fab>a>i>span{
      color: black;
      font-size: 1rem;
  }

  .none-link{
    pointer-events: none;
  }
  .none-link-mark{
    opacity: .5;
  }
  .add-break-time-form>p{
    margin: 0;
    text-align: left;
  }
  /*カレンダー入力フォーム
  .react-datepicker-wrapper{
    position: initial;
  }
  */

  /* hr */
  .strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
    margin: 1rem;
  }

  .strike > span {
      position: relative;
      display: inline-block;
  }

  .strike > span:before,
  .strike > span:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: red;
  }

  .strike > span:before {
      right: 100%;
      margin-right: 15px;
  }

  .strike > span:after {
      left: 100%;
      margin-left: 15px;
  }

  .btn-dispaly-no{
    display: none;
  }
  .rmdp-container{
    display: initial !important;
    height: initial !important;
  }
  .rmdp-input{
    height: initial !important;
    
  }